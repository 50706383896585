import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { pagesPaths } from 'common/router/routes.utils'
import { auth } from './auth/auth.singleton'

const authMiddleware = async (config: AxiosRequestConfig<any>) => {
  try {
    const accessToken = await auth.getSessionToken()
    if (config?.headers && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
  } catch (error) {
    console.error('Error in authMiddleware', error)
  }

  return config
}

const responseMiddleware = <T>(res: T) => res

const responseErrorMiddleware = async (err: AxiosError) => {
  const status = err.response?.status || 500

  if (status === 401) {
    if (typeof window !== undefined) {
      auth.clientLogout()
      if (typeof window !== undefined) window.location.replace(`${pagesPaths.login}?redirect=${window.location.href}`)
    }
  }

  return Promise.reject(err)
}

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SALIAJOB_API_BASE_URL,
})

http.interceptors.request.use(authMiddleware)
http.interceptors.response.use(responseMiddleware, responseErrorMiddleware)

export const httpPublic = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SALIAJOB_API_BASE_URL,
})

httpPublic.interceptors.request.use(authMiddleware)

export const httpServer = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SALIAJOB_API_BASE_URL,
})

export { authMiddleware, http }
